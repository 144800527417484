import axios from 'axios';
import React from 'react';
import './styles/RutaDetalle.css';
import IconoRN from '../components/IconoRN';
import IconoRP from '../components/IconoRP';

class RutaDetalle extends React.Component {

  state = {
    loading: true,
    error: null,
    data: []
  }

  componentDidMount(){
    this.fetchRuta();
  }

  fetchRuta = async () => {
    this.setState({ loading: true, error: null });
    
    try {
      const response = await axios.get(`https://localhost:5001/api/Rutas/${this.props.match.params.id}`);

      this.setState({
        data: response.data,
        loading: false
      });
    }
    catch(error){
      this.setState({loading: false, error: error});
    }
  }

  render() {

    console.log(this.state);
    if (this.state.loading)  {
      return 'Loading...';
    }

    if (this.state.error)  {
      return 'Error: '+ this.state.error;
    }

    return (
      <React.Fragment>
        
        <h1>
        { this.state.data.jurisdiccion === 'AR' &&
          <IconoRN numero={this.state.data.numero} ancho="100" /> 
        }

        { this.state.data.jurisdiccion.includes('AR') && this.state.data.jurisdiccion.length > 2 &&
          <IconoRP numero={this.state.data.numero} ancho="100" /> 
        }

        Ruta: {this.state.data.nombre}</h1>
        </React.Fragment>
    );
  }
}

export default RutaDetalle;