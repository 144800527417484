import React from 'react';
import './styles/Layout.css';
import Navbar from './Navbar';

function Layout(props) {
  // const children = props.children;

  return (
    <React.Fragment>
      <Navbar />
      <div className="container-fluid">
      {props.children}
      </div>
      <footer className="text-center">&copy; 2021 Centraldev - All rights reserved</footer>
    </React.Fragment>
  );
}

export default Layout;