import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Rutas from '../components/Rutas';

class Home extends Component {
  render() {
    return (
      <div className="text-center">
        <Rutas />
      </div>
    );
  }
}

export default Home;